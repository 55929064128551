import styled from "@emotion/styled";

// This allows the carousel to take full height of the flex parent
export const CarouselContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  * {
    height: 100%;
  }
`;
