import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { css } from "@emotion/react";

import { CATEGORIES_ROUTE } from "@routes";

import Layout from "layouts/Layout";

import Button from "components/Button";

import HomeCarousel from "pagesComponents/Home/HomeCarousel";

const StyledButton = (theme) => css`
  position: fixed;
  left: 50%;
  bottom: 29px;
  transform: translateX(-50%);

  @media (min-width: ${theme.breakpoints.md}) {
    bottom: 76px;
  }
`;

const IndexPage = ({ data }) => (
  <Layout fullWidthContent={true} displayFooter={false}>
    <HomeCarousel
      images={data.contentfulCarousel.photos.map((photo) => photo.fluid)}
    />
    <Button
      css={StyledButton}
      as={Link}
      variation="outlined"
      to={CATEGORIES_ROUTE}
    >
      Entrar
    </Button>
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const query = graphql`
  query {
    contentfulCarousel(title: { eq: "HomeCarousel" }) {
      photos {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
