import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import { CarouselContainer } from "./HomeCarousel.styles";

import Carousel from "components/Carousel";

const HomeCarousel = ({ images }) => (
  <CarouselContainer>
    <Carousel>
      {images.map((image, index) => (
        <Img
          key={`home-carousel-slide-${index}`}
          fluid={image}
          imgStyle={{ objectPosition: "70% 0%" }}
          role="presentation"
        />
      ))}
    </Carousel>
  </CarouselContainer>
);

HomeCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};

export default HomeCarousel;
